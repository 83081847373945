.container {
	display:flex;
	flex:1;
	flex-direction:row;
	width:100%;
	background:#f4f3ef;
	overflow:hidden;
}
.container .main {
	flex:1;
	padding:15px;
	padding-top:0;
	overflow:auto;
}
.container .menu {
	width:240px;
	height:100%;
    padding:0 20px 0;
	background:#fff;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
}
.container .main h1 {font-size:28px;}
.container .menu img {
	margin-top:10px;
}
.container .menu ul {
	margin:20px 0;
}
.container .menu ul li {
	padding:1px 0;
}
.container .menu ul li a {
	display:block;
	text-decoration:none;
	color:#555;
}
.container .menu ul li:hover a {
	color:#000;
	background:#eee;
}
.container .menu .logoff {
	margin-top:50px;
}
.container .menu .logoff a {
	font-size:100%;
	color:#777;
	text-decoration:none;
}
.confirmcontainer {
	width:340px;
	padding:20px;
	border-radius:5px;
	background:#fff;
}
.confirmcontainer h4 {
	margin:0 0 20px 0 !important;
	font-weight:normal;
}
.confirmcontainer .buttons {
	display:flex;
	justify-content:flex-end;
	align-items:center;
	margin-top:30px;
}
.confirmcontainer .buttons button {
	border:none;
    background:#ea6060;
    color:#fff;
	padding:8px 16px;
	margin-right:30px;
    border-radius:4px;
}
.confirmcontainer .buttons button, .confirmcontainer .buttons a {
	cursor:pointer;
	text-decoration:none;
}
.confirmcontainer .buttons a {color:#555;}