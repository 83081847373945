.error-container {
	width:100%;
	height:100%;
	background:#f4f3ef;
	overflow:hidden;
}
.error-container p {
	margin:20px 0;
	font-size:16px;
	text-align:center;
}
.error-container h1 {
	text-align:center;
	margin-bottom:50px;
	line-height:1.5;
}
.error-container h1 span  {
	font-size:50%;
	font-weight:normal;
	display:block;
	color:#555;
}
.error-container img {
	height:100px;
	margin:140px auto 80px;
	display:block;
}
.error-container a.btn {
	display:block;
	width:240px;
	padding:15px 0;
	margin:40px auto 0;
	border-radius:6px;
	border:none;
    box-shadow:0 2px 1px 0 rgba(255,229,59,.36);
    background:#226df6;
	font-size:18px;
	color:#fff;
	transition:.3s;
	display:flex;
	align-items:center;
	justify-content:center;
	text-decoration:none;
}
.error-container a.btn:hover {
	cursor:pointer;
	opacity:.8;
}