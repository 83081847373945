.stat-container {
	padding-top:25px;
}
.stat-container h4 {
	display:flex;
	align-items:center;
	justify-content:space-between;
}
.stat-container .controls {
	margin:20px 0;
}
.stat-container .controls select {
	padding:8px 10px;
	border:1px solid #ccc;
	border-radius:5px;
}
.stat-info-container {
	padding:20px;
	border-radius:4px;
	box-shadow:0 2px 2px rgba(204,197,185,.5);
	background:#fff;
}
.stat-info-container .block {
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:flex-start;
	margin-bottom:50px;
}
.stat-info-container .block .chart {
	width:33%;
	height:300px;
	margin-bottom:20px;
}
.stat-info-container h4 {
	margin-bottom:20px;
}
.stat-info-container .list {
	background:#f2f2f2;
	padding:6px 12px;
	border-radius:5px;
}
.stat-info-container .list ul {
	columns:3;
}
.stat-info-container .list ul li {
	display:flex;
	flex-direction:row;
	justify-content:flex-start;
	align-items:flex-start;
}
.stat-info-container .list ul li div {
	margin-right:5px;
}
.stat-info-container .list ul li div.num {
	color:#aaa;
}
.stat-info-container .list ul li div.count {
	margin-right:0;
	color:#333;
	font-weight:bold;
}
.stat-info-container .empty {
	margin-right:auto;
}